import React, { useEffect, useState } from "react";
import { getNestedLocations, useWidgetSettings, WidgetLocationSettings } from "../../../contexts/WidgetSettingsContext";
import { Container, MerchantLocation } from "./style";

export function LocationList() {
    const { widgetSettings, setWidgetId } = useWidgetSettings();
    const [localLocations, setLocalLocations] = useState<WidgetLocationSettings[]>([]);
    const [parentFolders, setParentFolders] = useState<string[]>([]);

    const currentDepth = parentFolders.length + 1;

    useEffect(() => {
        if (widgetSettings?.locationSettings) {
            const nestedLocations = getNestedLocations(
                widgetSettings.locationSettings,
                currentDepth,
                parentFolders
            );
            setLocalLocations(nestedLocations);
        }
    }, [parentFolders, widgetSettings, currentDepth]);

    return (
        <Container>
            {parentFolders.length > 0 && (
                <button
                    style={{
                        width: "fit-content",
                        borderRadius: "12px",
                        borderStyle: "solid",
                        border: `1px solid ${widgetSettings?.customStyles?.secondaryColour || "inherit"}`,
                        backgroundColor: "transparent",
                        color: widgetSettings?.customStyles?.secondaryColour || "inherit",
                        marginBottom: "8px",
                        cursor: "pointer",
                        height: "fit-content",
                        lineHeight: "1rem",
                        padding: "0.25rem 0.5rem",
                        textTransform: "none",
                    }}
                    onClick={() => {
                        setParentFolders((prevFolders) => prevFolders.slice(0, -1));
                    }}
                >
                    Back
                </button>
            )}
            {localLocations.map((location) => (
                <MerchantLocation
                    key={location.widgetId}
                    {...widgetSettings?.customStyles}
                    onClick={() => {
                        const clickedFolder = location.locationName;
                        const newParentFolders = [...parentFolders, clickedFolder];
                        const nextDepth = newParentFolders.length + 1;
                        const nextLocations = getNestedLocations(
                            widgetSettings?.locationSettings || [],
                            nextDepth,
                            newParentFolders
                        );

                        if (nextLocations.length === 0) {
                            setWidgetId(location.widgetId);
                        } else {
                            setParentFolders(newParentFolders);
                        }
                    }}
                >
                    {location.locationName}
                </MerchantLocation>
            ))}
        </Container>
    );
}