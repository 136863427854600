import {
  button_image_url, external_contact_form,
  extra_margin_bottom,
  extra_margin_right, hide_contact_form, logo_base64,
  primary_colour,
  secondary_colour, success_subtitle, success_title, widget_size
} from "..";
import LocalStorageService from "./localStorage";

export type ChannelTypes = 'whatsapp' | 'sms' | 'messenger' | 'instagram' | 'email' | 'unknown';
export class WidgetDomain {
  constructor(
    public widgetId: string,
    public merchantPublicId: string,
    public channels: ChannelDomain[],
    public logo: string,
    public locationName: string,
    public externalContactFormUrl?: string,
    public customStyles?: WidgetCustomStyles,
    public size?: "sm" | "md" | "lg",
    public hidePoweredBy?: boolean,
    public preventAutoOpen?: boolean,
    public delayAutoOpen?: number,
    public buttonTitle?: string,
    public merchantCountry?: string,
    public successTitle?: string,
    public hideContactForm?: boolean,
    public successSubtitle?: string,
    public buttonImageUrl?: string,
  ) {}
}
export class ChannelDomain {
  constructor(
    public id: string,
    public type: ChannelTypes,
    public handle: string,
    public link: string
  ) {}
}
export class WidgetCustomStyles {
  constructor(public primaryColour?: string, public secondaryColour?: string, public extraMarginBottom?: number, public extraMarginRight?: number) {}

  public static fromDto(
    customConfig?: CustomConfigDto
  ): WidgetCustomStyles | undefined {
    if (customConfig) {
      return new WidgetCustomStyles(
        customConfig.primary_colour,
        customConfig.secondary_colour
      );
    }
    return undefined;
  }
}

type CustomConfigDto = {
  primary_colour?: string;
  secondary_colour?: string;
  contact_form_url?: string;
  hide_powered_by?: boolean;
  prevent_auto_open?: boolean;
  delay_auto_open?: number;
  success_title?: string;
  hide_contact_form?: boolean;
  success_subtitle?: string;
  size?: "sm" | "md" | "lg";
  button_image_url?: string;
};

type WidgetDto = {
  id: string;
  merchant_public_id: string;
  channels: {
    id: string;
    channel: string;
    handle: string;
    link: string;
  }[];
  custom_config?: CustomConfigDto;
  location_name: string;
  button_title?: string;
  logo: string;
  merchant_country?: string;
};

export default class WidgetService {
  public static async getAllByIds(
    merchant_uuids: string[]
  ): Promise<WidgetDomain[]> {
    const widgets: WidgetDomain[] = [];

    try {
      const query = new URLSearchParams();
      merchant_uuids
        .filter(mid => !!mid)
        .map(mid => mid.replace(/[{}]/g, ""))
        .forEach((merchant_uuid) => {
          const existingWidget = LocalStorageService.getWithExpiration<WidgetDomain>(merchant_uuid);

          if (existingWidget) {
            widgets.push(this.enrichWithPreviewConfig(existingWidget));
          } else {
            query.append("widget_ids", merchant_uuid);
          }
        });

      if (!query.toString()) {
        return widgets;
      }

      const response = await fetch(
        `${process.env.REACT_APP_FUZEY_API_URL}/public/widget?${query.toString()}`
      );
      const response_data = (await response.json()) as WidgetDto[];

      response_data.forEach((widgetDto) => {
        const channels = widgetDto.channels
          .map(
            (channel) =>
              new ChannelDomain(
                channel.id,
                this.convertFromChannel(channel.channel),
                channel.handle,
                channel.link
              )
          )
          .filter((channel) => channel.type !== "unknown");

        const widget = new WidgetDomain(
          widgetDto.id,
          widgetDto.merchant_public_id,
          channels,
          widgetDto.logo,
          widgetDto.location_name,
          widgetDto.custom_config?.contact_form_url,
          WidgetCustomStyles.fromDto(widgetDto.custom_config),
          widgetDto.custom_config?.size,
          widgetDto.custom_config?.hide_powered_by,
          widgetDto.custom_config?.prevent_auto_open,
          widgetDto.custom_config?.delay_auto_open,
          widgetDto.button_title,
          widgetDto.merchant_country,
          widgetDto.custom_config?.success_title,
          widgetDto.custom_config?.hide_contact_form,
          widgetDto.custom_config?.success_subtitle,
          widgetDto.custom_config?.button_image_url,
        );

        LocalStorageService.setWithExpiration(widget.widgetId, widget);
        widgets.push(this.enrichWithPreviewConfig(widget));

        return;
      });

      return widgets;
    } catch (e) {
      return [];
    }
  }

  private static enrichWithPreviewConfig(widget: WidgetDomain): WidgetDomain {
    return {
      ...widget,
      customStyles: {
        primaryColour: primary_colour || widget.customStyles?.primaryColour,
        secondaryColour: secondary_colour || widget.customStyles?.secondaryColour,
        extraMarginBottom: extra_margin_bottom || 0,
        extraMarginRight: extra_margin_right || 0,
      },
      size: widget_size || widget.size || "md",
      externalContactFormUrl:
        external_contact_form || widget.externalContactFormUrl,
      logo: logo_base64 || widget.logo,
      successTitle: success_title || widget.successTitle,
      hideContactForm: hide_contact_form === "true" || widget.hideContactForm,
      successSubtitle: success_subtitle || widget.successSubtitle,
      buttonImageUrl: button_image_url || widget.buttonImageUrl,
    };
  }

  private static convertFromChannel(channelType: string): ChannelTypes {
    switch (channelType) {
      case "facebook":
        return "messenger";
      case "instagram":
        return "instagram";
      case "sms":
        return "sms";
      case "whatsapp":
        return "whatsapp";
      case "email":
        return "email";
      default:
        return "unknown";
    }
  }
}
